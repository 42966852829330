import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "views/common/layout";
import ApiService from "../../services/ApiService";
import EventView from 'components/Events/view';
import { FormGroup, Input, Button, Row, Col } from "reactstrap";
import ApprovalBar from "../../components/Events/approvalBar";


export default (() => {

    const { id, token, approve } = useParams();
    const [event, setEvent] = useState(null);
    const [response, setResponse] = useState(null);
    const getData = async () => {
        ApiService.get(`api/events/approve/${id}/${token}`)
            .then(data => {
                setEvent(data);                
            });
    }

 

    useEffect(() => {
        getData();
    }, []);


    return <><Layout title={`View Event / ${event ? event.title : ''}`}
        right={event && !event.responded && <ApprovalBar id={id} token={token} onEventApproval={(r) => {
            setResponse(r);
            if (r.success) getData();
        }} />}
    >
        {response && <div className={`alert ${response.success ? 'alert-success' : 'alert-danger'}`}>
            <p className="mb-0">{response.message}</p>
        </div>}
        {event && <EventView event={event} />}
    </Layout></>
})