import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";

import FullCalendar from '@fullcalendar/react'; // must go before plugins
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import momentPlugin from '@fullcalendar/moment';
import rrulePlugin from '@fullcalendar/rrule'
import auLocale from '@fullcalendar/core/locales/en-au';

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    UncontrolledDropdown,
    DropdownToggle,
    Button,
    Media,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
// core components
import Header from "components/Headers/Header.js";
import moment from "moment";
import ApiService from "../../services/ApiService";
import './calendars.css';

const unapprovedColour = '#b3b3b3';

export default ({ navigation }) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [calendars, setCalendars] = useState(null);
    const [locations, setLocations] = useState(null);
    const [fileSemesters, setFileSemesters] = useState([]);
    const [events, setEvents] = useState([]);
    const [showMenu, setShowMenu] = useState(false);
    const history = useHistory();
    const { date, view } = useParams();
    const calendar = useRef();
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const toggleMenu = () => {
        setShowMenu(prevState => !prevState);
    }

    const getDuration = (start, end) => {
        var diff = Math.abs(new Date(start) - new Date(end));
        return Math.floor((diff / 1000) / 60);
    }

    const generateRRule = (repeatFrequency, start, end, repeatUntil) => {
        let rrule = null;
        if (repeatFrequency != 0) {
            var dRepeatUntil = new Date(repeatUntil);

            rrule = {
                dtstart: start,
                until: dRepeatUntil
            };

            switch (repeatFrequency) {
                case 10:
                    rrule.freq = 'daily';
                    break;
                case 20:
                    rrule.freq = 'weekly';
                    break;
                case 30:
                    rrule.freq = 'weekly';
                    rrule.interval = 2;
                    break;
                case 40:
                    rrule.freq = 'monthly';
                    break;
                case 50:
                    rrule.freq = 'yearly';
                    break;
            }
        }
        return rrule;
    }

    const getData = async (cals, locs) => {
        let startDate = new moment(date);
        let endDate = new moment(date);

        if (!calendars && !locs && !view && !date)
            return;

        if (!calendars || calendars.length === 0)
            setEvents([]);

        switch (view) {
            case 'day':
                endDate = endDate.add(1, 'days');
                break;
            case 'month':
                startDate = startDate.startOf('month');
                endDate = endDate.add(1, 'months');
                break;
            default:
                startDate = startDate.startOf('week');
                endDate = endDate.add(1, 'week');
                break;
        }
        const start = startDate.format('yyyy-MM-DD');
        const end = endDate.format('yyyy-MM-DD');

        if (!(cals || calendars) || (cals || calendars).length === 0) {
            setEvents([]);
            return;
        };
        const calendarMap = (cals || calendars).filter(x => x.selected == true).map(x => x.id).join('&calendars=');

        if (!(locs || locations) || (locs || locations).length === 0) {
            setEvents([]);
            return;
        };
        const locationMap = (locs || locations).filter(x => x.selected == true).map(x => x.id).join('&locations=');


        const url = `api/events/${start}/${end}?calendars=${calendarMap}&locations=${locationMap}`;
        const eventResponse = await ApiService.get(url);
        if (eventResponse && eventResponse.success == false) {
            setEvents([]);
            return;
        };

        const events = eventResponse.events;

        eventResponse.fileSemesters && eventResponse.fileSemesters.forEach(function (part, index) {
            part.start = new Date(part.startDate);
            part.end = new Date(part.endDate);
            part.startOfWeek = new moment(part.start).startOf('week');
        });
        if (!fileSemesters || fileSemesters.length === 0)
            setFileSemesters(eventResponse.fileSemesters);
        const eventArray = [];
        for (var i = 0; i <= events.length - 1; i++) {

            var approved = events[i].eventCalendars.filter(x => x.approved != true).length == 0;
            const firstCalendar = '#' + events[i].eventCalendars.filter(x => x.approved == true)[0]?.colour;

            /*
                None = 0,
                Daily = 10,
                Weekly = 20,
                Fortnightly = 30,
                Monthly = 40,
                Yearly = 50
            */




            const evntObject = {
                duration: {
                    minutes: getDuration(events[i].start, events[i].end)
                },
                title: events[i].title,
                start: events[i].start,
                end: events[i].end,
                id: events[i].id,
                allDay: events[i].allDay,
                color: approved ? firstCalendar : unapprovedColour,
                textColor: getContrastYIQ(approved ? firstCalendar : unapprovedColour),
                rrule: generateRRule(events[i].repeatFrequency, events[i].start, events[i].end, events[i].repeatUntil)
            };

            eventArray.push(evntObject);
            if (events[i].requiresLoadInOut) {
                const loadInObject = {
                    duration: {
                        minutes: getDuration(events[i].loadInStart, events[i].start)
                    },
                    title: events[i].title + ' (Load In)',
                    start: events[i].loadInStart,
                    end: events[i].start,
                    id: events[i].id,
                    allDay: false,
                    color: approved ? firstCalendar : unapprovedColour,
                    textColor: getContrastYIQ(approved ? firstCalendar : unapprovedColour),
                    rrule: generateRRule(events[i].repeatFrequency, events[i].loadInStart, events[i].start, events[i].repeatUntil)
                };

                const loadOutObject = {
                    duration: {
                        minutes: getDuration(events[i].end, events[i].loadOutEnd)
                    },
                    title: events[i].title + ' (Load Out)',
                    start: events[i].end,
                    end: events[i].loadOutEnd,
                    id: events[i].id,
                    allDay: false,
                    color: approved ? firstCalendar : unapprovedColour,
                    textColor: getContrastYIQ(approved ? firstCalendar : unapprovedColour),
                    rrule: generateRRule(events[i].repeatFrequency, events[i].end, events[i].loadOutEnd, events[i].repeatUntil)
                };



                eventArray.push(loadInObject);
                eventArray.push(loadOutObject);
            }
        }
        setEvents(eventArray);
    }

    const getCalendars = () => {

        ApiService.get("/api/calendars").then(data => {
            data.forEach(function (part, index) {
                part.selected = true;
            });
            setCalendars(data);

            getLocations(data);
            
        })
    }
    const getLocations = (calendars) => {

        ApiService.get("/api/locations").then(data => {
            data.forEach(function (part, index) {
                part.selected = true;
            });
            setLocations(data);
            getData(calendars, data);
        })
    }

    const getWeekNumber = (currentDate) => {
        if (!fileSemesters) return;
        const calculatedDate = new moment(currentDate).add(1, 'days').startOf('week');
        const semester = fileSemesters.find(x => x.startOfWeek <= calculatedDate && x.end >= calculatedDate);

        if (!semester) return null;

        const startOfWeek = new moment(currentDate).startOf('week');

        var weeks = Math.round((startOfWeek.toDate() - semester.startOfWeek.toDate()) / (7 * 24 * 60 * 60 * 1000)) + 1;

        if (currentDate.getDay() === 0)
            weeks--;

        if (weeks == 0) return null;



        return `${semester.year} S${semester.semester} W${weeks}`
    }

    useEffect(() => {
        if (calendars && view && date)
            getData();
    }, [view, date])



    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        getCalendars();
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }

    }, []);

    const isMobile = width <= 768;

    let initialView = view || sessionStorage.getItem('calendarView');

    switch (initialView) {
        case 'day':
            initialView = 'timeGridDay';
            break;
        case 'week':
            initialView = 'timeGridWeek';
            break;
        case 'month':
            initialView = 'dayGridMonth';
            break;
    }

    if (!initialView) {
        initialView = (isMobile ? 'timeGridDay' : 'timeGridWeek');
    }

    const toggleCalendar = (calendarId) => {
        const calendarIndex = calendars.findIndex(x => x.id === calendarId);
        if (calendarIndex < 0) return;

        setCalendars(prevState => {
            const newState = [...prevState];
            newState[calendarIndex].selected = !newState[calendarIndex].selected;
            getData(newState, locations);
            return newState;
        });
    }

    const toggleLocation = (locationId) => {
        const locationIndex = locations.findIndex(x => x.id === locationId);
        if (locationIndex < 0) return;

        setLocations(prevState => {
            const newState = [...prevState];
            newState[locationIndex].selected = !newState[locationIndex].selected;
            getData(calendars, newState);
            return newState;
        });
    }

    const getContrastYIQ = (hexcolor) => {
        hexcolor = hexcolor.replace("#", "");
        var r = parseInt(hexcolor.substr(0, 2), 16);
        var g = parseInt(hexcolor.substr(2, 2), 16);
        var b = parseInt(hexcolor.substr(4, 2), 16);
        var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return (yiq >= 128) ? 'black' : 'white';
    }

    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="bg-transparent">
                                <Row>
                                    <Col>
                                        <h3 className="mb-0">Calendar</h3>
                                    </Col>
                                    <Col>

                                        <UncontrolledDropdown nav className="float-right">
                                            <DropdownToggle className="pr-0" nav>
                                                <Media className="align-items-center">
                                                    <Button outline color="primary" onClick={toggleMenu}>
                                                        <FontAwesomeIcon icon={faCalendar} size="1x" className="mr-2" /> Select Calendars
                                                    </Button>
                                                </Media>
                                            </DropdownToggle>

                                            <div className={"dropdown-menu-arrow dropdown-menu dropdown-menu-right " + (showMenu ? 'show' : 'hide')} style={{ width: '400px', maxWidth: '80vw' }}>
                                                <Row>
                                                    <Col md={6}>
                                                        <div className="text-uppercase text-muted small  px-3 my-2">
                                                            Calendars
                                                        </div>
                                                        {calendars && calendars.map(calendar => <div onClick={() => { toggleCalendar(calendar.id) }} key={calendar.id} className="d-flex align-items-center px-3 my-2" style={{ fontSize: '0.85rem', cursor: 'pointer' }}>
                                                            <span style={{ display: 'block', borderWidth: 1, borderStyle: 'solid', borderRadius: 14, height: 14, width: 14, borderColor: `#${calendar.colour}`, float: 'left', backgroundColor: (calendar.selected === true ? `#${calendar.colour}` : '#fff') }} className="mr-2"></span> {calendar.name}
                                                        </div>)}
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="text-uppercase text-muted small  px-3 my-2">
                                                            Locations
                                                        </div>
                                                        {locations && locations.map(location => <div onClick={() => { toggleLocation(location.id) }} key={locations.id} className="d-flex align-items-center px-3 my-2" style={{ fontSize: '0.85rem', cursor: 'pointer' }}>
                                                            <span style={{ display: 'block', borderWidth: 1, borderStyle: 'solid', borderRadius: 14, height: 14, width: 14, borderColor: `#68bd45`, float: 'left', backgroundColor: (location.selected === true ? `#68bd45` : '#fff') }} className="mr-2"></span> {location.name}
                                                        </div>)}
                                                    </Col>
                                                </Row>


                                                <div className="px-3 my-2" style={{ fontSize: '0.85rem', cursor: 'pointer' }}>
                                                    <Button color="primary" size="sm" className="w-100 mt-3" onClick={toggleMenu} >
                                                        Done
                                                    </Button>
                                                </div>


                                            </div>
                                        </UncontrolledDropdown>


                                    </Col>

                                </Row>

                            </CardHeader>
                            <CardBody>
                                {calendars && <FullCalendar
                                    firstDay={1}
                                    ref={calendar}
                                    plugins={[dayGridPlugin, timeGridPlugin, momentPlugin, interactionPlugin, rrulePlugin]}
                                    initialView={initialView}
                                    weekends={true}
                                    nowIndicator={true}
                                    initialDate={date}
                                    titleFormat='dddd, MMMM D, YYYY'
                                    locale={auLocale}
                                    selectable={true}
                                    dayHeaderContent={(arg) => {
                                        let title = document.createElement('span')
                                        title.innerText = arg.text;
                                        let arrayOfDomNodes = [title]

                                        const weekNumberTitle = getWeekNumber(arg.date);
                                        if (weekNumberTitle) {
                                            let pElement = document.createElement('p');
                                            let smallElement = document.createElement('small');
                                            smallElement.innerHTML = weekNumberTitle
                                            pElement.appendChild(smallElement);
                                            arrayOfDomNodes.push(pElement);
                                        }

                                        return { domNodes: arrayOfDomNodes }
                                    }}
                                    headerToolbar={isMobile ? "" : {
                                        start: 'prev,next today',
                                        end: 'dayGridMonth,timeGridWeek,timeGridDay',
                                    }}
                                    datesSet={(event) => {
                                        const view = event.view.getCurrentData().currentViewType;
                                        let uiView = 'week';
                                        switch (view) {
                                            case 'timeGridDay':
                                                uiView = 'day';
                                                break;
                                            case 'timeGridWeek':
                                                uiView = 'week';
                                                break;
                                            case 'dayGridMonth':
                                                uiView = 'month';
                                                break;
                                        }


                                        sessionStorage.setItem('calendarView', uiView);
                                        const newDate = new moment(event.start).format('yyyy-MM-DD');

                                        history.push(`/admin/calendar/${newDate}/${uiView}`);
                                    }}
                                    eventAdd={(event) => {
                                    }}
                                    dateClick={(dateClickInfo) => {
                                        const startDate = dateClickInfo.date;
                                        const endDate = dateClickInfo.date;
                                        endDate.setMinutes(endDate.getMinutes() + 30);
                                        const url = `/admin/events/new?start=${startDate.getTime()}&end=${endDate.getTime()}&allDay=${dateClickInfo.allDay}`;
                                        //history.push(url);
                                    }}
                                    select={(selectionInfo) => {
                                        const url = `/admin/events/new?start=${selectionInfo.start.getTime()}&end=${selectionInfo.end.getTime()}&allDay=${selectionInfo.allDay}`;
                                        history.push(url);
                                    }}
                                    events={events}
                                    businessHours={{
                                        daysOfWeek: [1, 2, 3, 4, 5],
                                        startTime: '08:45',
                                        endTime: '15:30',
                                    }}
                                    eventClick={(info) => history.push(`/admin/events/view/${info.event.id}/`)}
                                />}
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>);

}