import React, { useEffect, useState } from "react";
import { FormGroup, Input, Button, Row, Col } from "reactstrap";
import ApiService from "../../services/ApiService";
import CustomButton from "../Common/customButton";


export default (({ id, token, approve, onEventApproval }) => {

    const [loading, setLoading] = useState(null);

    const doApproval = (approveEvent) => {
        setLoading(approveEvent);
            ApiService.post(`api/events/approve/${id}/${token}/${approveEvent.toString().toLowerCase()}`, { approve: approveEvent })
                .then(data => {
                    setLoading(null);
                    onEventApproval(data);
                }
                );
    }

    //useEffect(() => {
    //    if (approve === 'true' || approve === 'false') {
    //        doApproval(approve);
    //    }
    //});

    return <div>
        <CustomButton
            color="primary"
            className=""
            onClick={() => doApproval(true)}
            disabled={loading != null}
            loading={loading === true}
        >
            Approve
        </CustomButton>
        <CustomButton
            color="danger"
            className=""
            onClick={() => doApproval(false)}
            disabled={loading != null}
            loading={loading === false}
        >
            Deny
        </CustomButton>
    </div>;

})