import React, { useEffect, useState } from "react";
import Layout from "views/common/layout";
import ApiService from "../../services/ApiService";
import moment from "moment";
import {
    Button,
    Table
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarCheck, faCheck } from '@fortawesome/free-solid-svg-icons'


export default ({ location }) => {
    const history = useHistory();
    const [notifications, setNotifications] = useState(null);

    const getData = async () => {
        var data = await ApiService.get(`api/notifications/`);
        setNotifications(data);
    };


    useEffect(() => {
        getData();
    }, []);
    const toggleNotification = async (type, id, subscribe) => {

        var obj = { subscribe };
        if (type === 'location') {
            obj.locationId = id;
        } else {
            obj.calendarId = id;
        }

        var data = await ApiService.post(`api/notifications/toggle`, obj);
        setNotifications(data);

    }

    const renderRow = (type, entity) => {
        return <tr key={`${type}_${entity.id}` }>
            <th scope="row">

                <span className="mb-0 text-sm">
                    {entity.name}
                </span>
            </th>
         
            <td className="text-right">
                <Button
                    color={entity.subscribed ? "danger" : "primary"}
                    className=""
                    size="sm"
                    onClick={() => toggleNotification(type, entity.id, !entity.subscribed)}
                >
                    {entity.subscribed ? "Unsubscribe" : "Subscribe"}
                </Button>
            </td>
        </tr>
    }

    return (<>
        <Layout title={`Notifications`}>
          
            <div className="row">
                <div className="col">
                    {notifications && notifications.calendars && <Table className="align-items-center table-flush mb-3" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Calendar</th>
                                <th scope="col" />
                            </tr>
                        </thead>
                        <tbody>
                            {notifications && notifications.calendars.map(calendar => renderRow('calendar', calendar))}
                        </tbody>
                    </Table>}

                    {notifications && notifications.locations && <Table className="align-items-center table-flush mb-3" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Location</th>
                                <th scope="col" />
                            </tr>
                        </thead>
                        <tbody>
                            {notifications && notifications.locations.map(location => renderRow('location', location))}
                        </tbody>
                    </Table>}

                
                </div>
            </div>

        </Layout></>
    );
};
