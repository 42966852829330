import React, { useEffect, useState } from "react";
import Layout from "views/common/layout";
import ApiService from "../../services/ApiService";
import moment from "moment";
import {
    Button,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip,
} from "reactstrap";
import EventView from 'components/Events/view';
import { useHistory, useParams } from "react-router-dom";
import ApprovalBar from "../../components/Events/approvalBar";


export default () => {
    const { id } = useParams();
    const history = useHistory();
    const [approval, setApproval] = useState(null);
    const [response, setResponse] = useState(null);

    const getData = async () => {
        var data = await ApiService.get(`api/approvals/${id}`);
        setApproval(data);
    };

  
    useEffect(() => {
        getData();
    }, []);

    return (<>
        <Layout title={`Approval`} right={approval && !approval.responded && <ApprovalBar id={approval.approvalGuid} token={approval.approvalToken} onEventApproval={(r) => {
            setResponse(r);
            if (r.success) {
                history.push("/admin/approvals?eventApproved=1");
            };
        }} />}>
            {approval && <EventView event={approval} />}

        </Layout></>
    );
};
