import React from 'react';

export default (({ label, value }) => {
    return <><span className="form-control-label">
        {label}
    </span>
        <span className="d-block form-control-value mt-2">
            {value}
        </span>
    </>

});