/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ApiService from '../../services/ApiService';
// reactstrap components
import {
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Form,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    InputGroup,
    Navbar,
    Nav,
    Container,
    Media,
    Row,
    Col
} from "reactstrap";
import UserContext from "../../context/UserContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarCheck, faCalendar, faUserClock, faUser, faRightFromBracket, faBell } from '@fortawesome/free-solid-svg-icons'
const AdminNavbar = () => {
    const history = useHistory();
    const user = useContext(UserContext);
    const approval = JSON.parse(sessionStorage.getItem('approvals') || '{}');
    const [pendingApprovalCount, setPendingApprovalCount] = useState(approval?.count || 0);

    useEffect(() => {
        let interval;
        const timeoutDate = new Date();
        timeoutDate.setSeconds(timeoutDate.getSeconds - 30);
        if (!approval.lastUpdate || approval.lastUpdate < timeoutDate) {
            updateCount();
        }
        if (user.canApprove) {
            interval = setInterval(updateCount, 2000);
        }
        return () => clearInterval(interval);
    }, []);

    const updateCount = async () => {
        let interval;
        const approval = JSON.parse(sessionStorage.getItem('approvals') || '{}');
        const timeoutDate = new Date();
        timeoutDate.setSeconds(timeoutDate.getSeconds - 30);
        if (!approval.lastUpdate || approval.lastUpdate < timeoutDate) {
            ApiService.get("/api/me/pending-approvals").then(data => {
                if (!isNaN(data.count)) {
                    if (data.count != pendingApprovalCount)
                        setPendingApprovalCount(data.count);
                    sessionStorage.setItem('approvals', JSON.stringify({ count: data.count, lastUpdate: new Date() }));
                }
            });
        }
    }

    const logout = () => {
        user.logout();
        window.location.href = "https://homepage.westminster.sa.edu.au/"
    }

    return (
        <>
            <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
                <Container fluid>
                    <Link
                        className="h4 mb-0 text-white text-uppercase d-inline-block"
                        to="/"
                    >
                        <img
                            alt={'WestCalendar'}
                            className="navbar-brand-img"
                            src={require("../../assets/img/logo.svg").default}
                        />
                    </Link>
                    <div className="top-links">
                        <Link
                            className="h5 mb-0 text-white text-uppercase d-none d-lg-inline-block px-3 text-center"
                            to="/"
                        >
                            <FontAwesomeIcon icon={faCalendar} size="2x" /><br />Calendar
                        </Link>
                        {user && user.canApprove && <Link
                            className="h5 mb-0 text-white text-uppercase d-none d-lg-inline-block px-3 text-center"
                            to="/admin/approvals"
                        >
                            <span className="fa-layers fa-fw fa-2x">

                                <FontAwesomeIcon icon={faCalendarCheck} />
                                {pendingApprovalCount > 0 && <span className="fa-layers-counter fa-1x" style={{ background: 'Tomato', float: 'right' }}>{pendingApprovalCount}</span>}
                            </span>
                            <br />Approvals
                        </Link>}
                        <Link
                            className="h5 mb-0 text-white text-uppercase d-none d-lg-inline-block px-3 text-center"
                            to="/admin/my-events"
                        >
                            <FontAwesomeIcon icon={faUserClock} size="2x" /><br />My Events
                        </Link>
                    </div>
                    <Nav className="align-items-center d-none d-md-flex" navbar>
                        <UncontrolledDropdown nav>
                            <DropdownToggle className="pr-0" nav>
                                <Media className="align-items-center">
                                    <span className="avatar avatar-sm rounded-circle">

                                        <FontAwesomeIcon icon={faUser} size="1x" />
                                    </span>
                                    <Media className="ml-2 d-none d-lg-block">
                                        <span className="mb-0 text-sm font-weight-bold">
                                            {user.fullName}
                                        </span>
                                    </Media>
                                </Media>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>

                                <DropdownItem onClick={() => { history.push(`/admin/notifications`); }}>
                                    <FontAwesomeIcon icon={faBell} size="2x" />
                                    <span>Notifications</span>
                                </DropdownItem>
                                <DropdownItem divider />

                                <DropdownItem onClick={logout}>
                                    <FontAwesomeIcon icon={faRightFromBracket} size="2x" />
                                    <span>Logout</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>
                </Container>
            </Navbar>
        </>
    );
};

export default AdminNavbar;
