import StorageService from 'services/StorageService';

const apiUrl = "https://localhost:44370";

export default {
    get: async function postData(url = '', data = {}) {
        return await this.request(url, 'GET');
    },
    post: async function postData(url = '', data = {}) {
        return await this.request(url, 'POST', data);
    },
    put: async function postData(url = '', data = {}) {
        return await this.request(url, 'PUT', data);
    },
    delete: async function postData(url = '', data = {}) {
        return await this.request(url, 'DELETE', data);
    },
    request: async (url, method, data) => {
        const headers = {
            'Content-Type': 'application/json'
        };
        const user = StorageService.get(StorageService.keys.user);
    
        if (user && user.token) {
            headers['Authorization'] = `Bearer ${user.token}`;
        }
        const request = {
            method: method,
            mode: 'cors',
            credentials: 'same-origin',
            headers           
        }

        if (data && (method == 'POST' || method == 'PUT')) {
            request.body = JSON.stringify(data);
        }

        const response = await fetch(url, request);
        if (response.status === 200)
            return response.json();

        if (response.status === 400) {
            var json = await response.json();
            var errors = [];
            for (var key in json.errors) {
                for (var i = 0; i <= json.errors[key].length - 1; i++) {
                    errors.push(json.errors[key][i]);
                }
            }
            return {
                success: false,
                errors: errors
            }
        }
    }
}