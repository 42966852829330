import React from "react";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    Container,
    Row
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";


export default (({ title, right, children}) => {

    return <><Header />
    <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
            <div className="col">
                <Card className="shadow">
                    <CardHeader className="bg-transparent">
                            <h3 className="mb-0 float-left">{title}</h3>
                            {right && <div className="float-right">{right}</div>}
                    </CardHeader>
                    <CardBody>
                      {children}
                    </CardBody>
                </Card>
            </div></Row></Container></>
});