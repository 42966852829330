
import React, {useContext} from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from './views/auth/login';

export default (({ component: Component, authed, ...rest }) => {

    //const redirect = () => {
    //    window.location.href = "/api/auth";
    //    return "Loading...";
    //}

    return <><Route
        {...rest}
        render={(props) =>
            authed === true
                ? <Component {...props} />
                : <Redirect to={{ pathname: '/auth/index', state: { from: props.location } }} />}
        /></>;
    
});



