import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import ProtectedRoute from './ProtectedRoute';

import UserContext, { UserContextProvider } from "./context/UserContext";
import StorageService from "./services/StorageService";
import ApiService from "./services/ApiService";

export default (() => {
    const userContext = useContext(UserContext);
    const [userContextHasLoaded, setUserContextHasLoaded] = useState(false);

   

   

    return <UserContextProvider>
        <BrowserRouter>
            <Switch>
                <Route path="/admin" authed={userContext.isAuthenticated} render={(props) => <AdminLayout {...props} />}/>
                <Route path="/auth" authed={userContext.isAuthenticated} render={(props) => <AuthLayout {...props} />} />
                <Route
                    path="/auth/login"
                    render={(props) => <>Test</>}
                />


                <Redirect from="/admin/index" to="/admin/calendar" />
                <Redirect from="/" to="/admin/calendar" />

            </Switch>
        </BrowserRouter>
    </UserContextProvider>
});