import React, { useEffect, useState } from "react";
import Layout from "views/common/layout";
import ApiService from "../../services/ApiService";
import moment from "moment";
import {
    Button,
    Table,
    Badge
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faCheck } from '@fortawesome/free-solid-svg-icons'


export default ({ location }) => {
    const history = useHistory();
    const [events, setEvents] = useState(null);
    const [page, setPage] = useState(1);

    const eventApproved = (location.search.match(/eventApproved=([^&]+)/) || [])[1]
    const getData = async () => {
        var data = await ApiService.get(`api/events/my/${page}`);
        setEvents(data);
    };

    const viewEvent = (id) => {
        const url = `/admin/events/view/${id}`;
        history.push(url);
    }

    useEffect(() => {
        getData();
    }, []);

    const eventRow = (event) => {
        let pendingApprovals = event.eventCalendars.filter(x => x.approved === null);
        let negativeApprovals = event.eventCalendars.filter(x => x.approved === null || x.approved === false);

        return <tr key={ event.id }>
            <th scope="row">

                <span className="mb-0 text-sm">
                    {event.title}
                </span>
            </th>
            <td>
                {new moment(event.start).format('dddd, MMMM Do YYYY, h:mm:ss a')}
            </td>
            <td>
                {new moment(event.end).format('dddd, MMMM Do YYYY, h:mm:ss a')}
            </td>
            <td>
                {pendingApprovals.length > 0 && <>
                    <Badge color="" className="badge-dot mr-4">
                        <i className="bg-warning" />
                        pending
                    </Badge>
                </>}
                {pendingApprovals.length === 0 && negativeApprovals.length > 0 && <>
                    <Badge color="" className="badge-dot mr-4">
                        <i className="bg-danger" />
                        Not Approved
                    </Badge>
                </>}
                {pendingApprovals.length === 0 && negativeApprovals.length === 0 && <>
                    <Badge color="" className="badge-dot mr-4">
                        <i className="bg-success" />
                        Approved
                    </Badge>
                </>}
            </td>
            <td>
                {event.eventCalendars.map(x=>x.name).join(',')}
            </td>
            <td className="text-right">
                <Button
                    color="primary"
                    className=""
                    size="sm"
                    onClick={() => viewEvent(event.id)}
                >
                    View Event
                </Button>
            </td>
        </tr>
    }

    return (<>
        <Layout title={`My Events`}>
            {(eventApproved === '1') && <div className="row">
                <div className="col">
                    <div className="alert alert-success">
                        <p className="mb-0 pb-0"> <FontAwesomeIcon icon={faCheck} className="mr-2" /> Your approval response has been recorded.
                        </p>
                    </div>
                </div>
            </div>}
            <div className="row">
                <div className="col">
                    {events && events.length > 0 && <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Title</th>
                                <th scope="col">Start Date</th>
                                <th scope="col">End Date</th>
                                <th scope="col">Approved</th>
                                <th scope="col">Calendar</th>
                                <th scope="col" />
                            </tr>
                        </thead>
                        <tbody>
                            {events && events.map(event => eventRow(event))}
                        </tbody>
                    </Table>}
                    {events && events.length === 0 &&
                        <div className="text-center">
                        <FontAwesomeIcon icon={faCalendar} size="4x" className="mb-3" />
                            <h3>You do not have any events</h3>
                            
                        </div>}
                </div>
            </div>

        </Layout></>
    );
};
