import React, { useEffect, useState } from "react";
import Layout from "views/common/layout";
import ApiService from "../../services/ApiService";
import moment from "moment";
import {
    Button,
    Table
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarCheck, faCheck } from '@fortawesome/free-solid-svg-icons'


export default ({ location }) => {
    const history = useHistory();
    const [approvals, setApprovals] = useState(null);

    const eventApproved = (location.search.match(/eventApproved=([^&]+)/) || [])[1]
    const getData = async () => {
        var data = await ApiService.get(`api/approvals/`);
        setApprovals(data);
    };

    const viewApproval = (id) => {
        const url = `/admin/approvals/view/${id}`;
        history.push(url);
    }

    useEffect(() => {
        getData();
    }, []);

    const approvalRow = (approval) => {
        return <tr key={ approval.id }>
            <th scope="row">

                <span className="mb-0 text-sm">
                    {approval.title}
                </span>
            </th>
            <td>
                {new moment(approval.startTime).format('dddd, MMMM Do YYYY, h:mm:ss a')}
            </td>
            <td>
                {new moment(approval.endTime).format('dddd, MMMM Do YYYY, h:mm:ss a')}
            </td>
            <td>
                {approval.eventCreator}
            </td>
            <td>
                {approval.calendar}
            </td>
            <td className="text-right">
                <Button
                    color="primary"
                    className=""
                    size="sm"
                    onClick={() => viewApproval(approval.approvalRequestId)}
                >
                    View Event
                </Button>
            </td>
        </tr>
    }

    return (<>
        <Layout title={`Pending Approvals`}>
            {(eventApproved === '1') && <div className="row">
                <div className="col">
                    <div className="alert alert-success">
                        <p className="mb-0 pb-0"> <FontAwesomeIcon icon={faCheck} className="mr-2" /> Your approval response has been recorded.
                        </p>
                    </div>
                </div>
            </div>}
            <div className="row">
                <div className="col">

                    {approvals && approvals.length > 0 && <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Title</th>
                                <th scope="col">Start Date</th>
                                <th scope="col">End Date</th>
                                <th scope="col">Created By</th>
                                <th scope="col">Calendar</th>
                                <th scope="col" />
                            </tr>
                        </thead>
                        <tbody>
                            {approvals && approvals.map(approval => approvalRow(approval))}
                        </tbody>
                    </Table>}
                    {approvals && approvals.length === 0 &&
                        <div className="text-center">
                        <FontAwesomeIcon icon={faCalendarCheck} size="4x" className="mb-3" />
                        <h3>You're all caught up!</h3>
                        <h4>Great job!</h4>
                        </div>}
                </div>
            </div>

        </Layout></>
    );
};
