import React, { useEffect, useState } from "react";
import moment from "moment";
import { FormGroup, Input, Button, Row, Col } from "reactstrap";
import FormValue from "../../views/common/formValue";
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'

export default ({ location }) => {

    const position = location.longitude && location.latitude ? [location.latitude, location.longitude] : null;
    return (<>

        {location &&
            <>

                <div className="well">
                    <Row className="mb-3">
                        <Col md="6">
                            <FormValue label="Location" value={location.name} />
                            <p>
                                {location.address1 ? <>{location.address1}<br /></> : ''}
                                {location.address2 ? <>{location.address2}<br /></> : ''}
                                {location.suburb ? <>{location.suburb}<br /></> : ''}
                                {location.state ? <>{location.state}<br /></> : ''}
                                {location.postcode ? <>{location.postcode}<br /></> : ''}
                                {location.countryName ? <>{location.countryName}</> : ''}
                            </p>
                        </Col>
                        <Col md="6">
                            {position && <><p>&nbsp;</p><MapContainer center={position} zoom={15} style={{ width: '100%', height: 400, maxWidth: '100%' }}>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker position={position}>
                                    <Popup>
                                        <b>{location.name}</b><br />
                                        {location.address1 ? <>{location.address1}<br /></> : ''}
                                        {location.address2 ? <>{location.address2}<br /></> : ''}
                                        {location.suburb ? <>{location.suburb}<br /></> : ''}
                                        {location.state ? <>{location.state}<br /></> : ''}
                                        {location.postcode ? <>{location.postcode}<br /></> : ''}
                                        {location.countryName ? <>{location.countryName}</> : ''}
                                    </Popup>
                                </Marker>
                            </MapContainer></>}
                        </Col>
                    </Row>
                </div>
            </>}</>
    );
};
