/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Callback from "../../components/Auth/Callback";
import Loader from "../../components/Common/loader/Loader";
import UserContext from "context/UserContext";
import ApiService from "../../services/ApiService";
import React, { useEffect, useContext, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Alert
} from "reactstrap";

export default (({ location }) => {
    const userContext = useContext(UserContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [errors, setErrors] = useState([]);
    const [authType, setAuthType] = useState(null);
    const [isOAuthCallback, setIsOAuthCallback] = useState(null);
    const history = useHistory();

    useEffect(() => {
        //set oauth callback:
        const code = location.search.match(/code=([^&]+)/)
        const state = location.search.match(/state=([^&]+)/)
        const isOauthCallback = code != null && state != null;
        setIsOAuthCallback(isOauthCallback);
        if (!isOauthCallback || code.length != 2 || state.length != 2) {
            var redirectUri = location?.state?.from?.pathname;

            window.location.href = `/api/auth${redirectUri ? `?redirectUrl=${redirectUri}` : ``}`;
} else {

        const data = { code: code[1], state: state[1] };
        ApiService.post("api/auth/oauth_token", data)
            .then(json => {
                if (!json) {
                    window.location.href = "/api/auth";
                }
                userContext.login(json);
                history.push('/admin/index');
            });
    }
}, []);

const handleOAuthCancel = (message) => { setIsOAuthCallback(false); setErrors([message]) }


return (<><Loader /></>)


});
