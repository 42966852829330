/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import Calendars from "views/calendars/calendars";
import Login from "views/auth/login";

import EventCreate from 'views/events/create';
import EventView from 'views/events/view';
import EventApprovalView from 'views/events/eventApproval';
import ApprovalList from 'views/approvals/approvalList';
import ApprovalView from 'views/approvals/approval';
import MyEvents from 'views/events/myEvents';
import Notifications from "./views/notifications/notifications";

var routes = [
    {
        path: "/calendar/:date?/:view?",
        name: "Calendar",
        icon: "ni ni-calendar-grid-58 text-primary",
        component: Calendars,
        layout: "/admin",
        showInMenu: true
    },
    {
        path: "/events/new",
        name: "Event",
        icon: "ni ni-calendar-grid-58 text-primary",
        component: EventCreate,
        layout: "/admin",
        showInMenu: false
    },
    {
        path: "/events/view/:id",
        name: "Event",
        icon: "ni ni-calendar-grid-58 text-primary",
        component: EventView,
        layout: "/admin",
        showInMenu: false
    },
    {
        path: "/events/approve/:id/:token/:approve?",
        name: "Event",
        icon: "ni ni-calendar-grid-58 text-primary",
        component: EventApprovalView,
        layout: "/admin",
        allowAnonymous: true,
        showInMenu: false
    },
    {
        path: "/approvals/view/:id",
        name: "Approval",
        icon: "ni ni-calendar-grid-58 text-primary",
        component: ApprovalView,
        layout: "/admin",
        allowAnonymous: false,
        showInMenu: false
    },
    {
        path: "/approvals",
        name: "Approvals",
        icon: "ni ni-calendar-grid-58 text-primary",
        component: ApprovalList,
        layout: "/admin",
        allowAnonymous: false,
        showInMenu: false
    },
    {
        path: "/my-events",
        name: "MyEvents",
        icon: "ni ni-calendar-grid-58 text-primary",
        component: MyEvents,
        layout: "/admin",
        allowAnonymous: false,
        showInMenu: false
    },
    {
        path: "/notifications",
        name: "Notifications",
        icon: "ni ni-calendar-grid-58 text-primary",
        component: Notifications,
        layout: "/admin",
        allowAnonymous: false,
        showInMenu: false
    },
    //{
    //    path: "/login",
    //    name: "Login",
    //    icon: "ni ni-key-25 text-info",
    //    component: Login,
    //    layout: "/admin",
    //    showInMenu: false,
    //},
];
export default routes;
