
export default {
    keys: {       
        user: `user`,
        sasToken: `sastoken`,
    },
    get: (key, defaultValue) => {
        var storageObject = localStorage.getItem(key);
        if (storageObject) {
            try {
                const jsonObject = JSON.parse(storageObject);
                return jsonObject;
            } catch (exp) {
                return defaultValue;
            }
        }
        return defaultValue;
    },
    set: (key, value) => {
        var strObject = JSON.stringify(value);
        localStorage.setItem(key, strObject);
    },
    remove: (key) => {
        localStorage.removeItem(key);
    },
    clear: () => localStorage.clear()
}
