import React, { useEffect, useState } from "react";
import moment from "moment";
import { FormGroup, Input, Button, Row, Col } from "reactstrap";
import FormValue from "../../views/common/formValue";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarCheck, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import Location from './location';
export default ({ event }) => {


	return (<>

		{event && 
			<>
			{event.eventCalendars.filter(x=>x.approved == null).length > 0 && <>
				<Row className="mb-3">
					<Col md="12">
						<div className="alert alert-warning">
							<p className="mb-0 pb-0"> <FontAwesomeIcon icon={faTriangleExclamation} className="mr-2" /> This event has pending approvals
							</p>
						</div>
					</Col>
				</Row>
			</>}

				<Row className="mb-3">
					<Col md="6">
						<FormValue label="Start Time" value={new moment(event.start).format('dddd, MMMM Do YYYY, h:mm a')} />
					</Col>
					<Col md="6">
						<FormValue label="End Time" value={new moment(event.end).format('dddd, MMMM Do YYYY, h:mm a')} />
					</Col>
				</Row>
			{event.repeats && <Row className="mb-3">
				<Col md="6">
					<FormValue label="Repeat Interval" value={event.repeatInterval} />
				</Col>
				<Col md="6">
					<FormValue label="Repeat Until" value={new moment(event.repeatUntil).format('dddd, MMMM Do YYYY')} />
				</Col>
			</Row>}
				{event.requiresLoadInOut &&
						<Row className="mb-3">
							<Col md="6">
								<FormValue label="Load In Start Time" value={new moment(event.loadInStart).format('dddd, MMMM Do YYYY, h:mm a')} />
							</Col>
							<Col md="6">
								<FormValue label="Load Out End Time" value={new moment(event.loadOutEnd).format('dddd, MMMM Do YYYY, h:mm a')} />
							</Col>
						</Row>
				}
				<Row className="mb-3">
					<Col md="12">
					<Location location={event.location} />
					</Col>
				</Row>
				<Row className="mb-3">
					<Col md="12">
						<FormValue label="Title" value={event.title} />
					</Col>
				</Row>
				<Row className="mb-3">
					<Col md="12">
						<FormValue label="Description" value={event.description} />
					</Col>
			</Row>
			<Row className="mb-3">
				<Col md="12">
					<FormValue label="Visibility" />
					{event.public ? <>This event is marked as <b>public</b></> : <>This event is marked as <b>private</b></>}
				</Col>
			</Row>
			<Row className="mb-3">
				<Col md="12">
					<FormValue label="Calendars" />
					{event.eventCalendars.map(calendar => <p key={calendar.id}>{calendar.name}</p>)}
				</Col>
			</Row>
			</>}</>
	);
};
