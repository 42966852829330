import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Layout from "views/common/layout";
import moment from "moment";
import ApiService from "../../services/ApiService";
import { FormGroup, Input, Button, Row, Col } from "reactstrap";
import CustomButton from "../../components/Common/customButton";
export default () => {
    const useQuery = () => new URLSearchParams(useLocation().search);
    let query = useQuery();
    const history = useHistory();
    const [calendars, setCalendars] = useState([]);
    const [booking, setBooking] = useState(null);
    const [errors, setErrors] = useState([]);
    const [locations, setLocations] = useState(null);
    const [loading, setLoading] = useState(false);

    const getData = async () => {
        var data = await ApiService.get('api/calendars');
        setCalendars(data);

        var locationData = await ApiService.get('api/locations');
        setLocations(locationData);

        setBooking(prevState => {
            let newState = { ...prevState };
            newState.locationId = locationData[0].id;
            return newState;            
        });
    };

    useEffect(() => {
        const defaultBooking = {
            allDay: query.get("allDay") == "true",
        };

        const qsStart = query.get("start");
        const qsEnd = query.get("end");

        if (qsStart) {
            const startDate = new Date();
            startDate.setTime(qsStart);
            defaultBooking.start = new moment(startDate).format("yyyy-MM-DDTHH:mm");
            defaultBooking.loadInStart = new moment(startDate).add(-1, 'hours').format("yyyy-MM-DDTHH:mm");
        }

        if (qsEnd) {
            const endDate = new Date();
            endDate.setTime(qsEnd);
            defaultBooking.end = new moment(endDate).format("yyyy-MM-DDTHH:mm");
            defaultBooking.loadOutEnd = new moment(endDate).add(1, 'hours').format("yyyy-MM-DDTHH:mm");
        }
        defaultBooking.repeatInterval = "Daily";
        setBooking(defaultBooking);
        getData();
    }, []);

    const updateBooking = (newValues) => {
        setBooking((prevState) => {
            let tempState = { ...prevState, ...newValues };
            return tempState;
        });
    };

    const validateBooking = () => {
        //ensure endtime is after starttime
        const errorList = [];

        if (booking.start && booking.end) {
            const start = new Date(booking.start);
            const end = new Date(booking.end);

            if (start > end)
                errorList.push("End date must come after the start date");

        } else if (!booking.start) {
            errorList.push("Please enter a start date/time");
        } else if (!booking.end) {
            errorList.push("Please enter an end date/time");
        }



        if (!booking.locationId || booking.locationId.length < 1)
            errorList.push("Please enter a location");

        if (!booking.title || booking.title.length < 1)
            errorList.push("Please enter an event title");

        if (!booking.description || booking.description.length < 1)
            errorList.push("Please enter an event description");

        if (!booking.calendars || booking.calendars.length === 0)
            errorList.push("Please select at least one calendar to assign this event to");

        setErrors(errorList);

        return errorList.length > 0
    }

    const submit = async () => {
        const isValid = !validateBooking();
        if (!isValid) return;
        setLoading(true);
        booking.startDate = new Date(booking.start).toJSON();
        booking.endDate = new Date(booking.end).toJSON();

        if (booking.requiresLoadInOut) {

            booking.loadInStart = new Date(booking.loadInStart).toJSON();
            booking.loadOutEnd = new Date(booking.loadOutEnd).toJSON();
        }
      
        var data = await ApiService.post('api/events', booking);

        if (!data.success) {
            setLoading(false);
            setErrors(data.errors);
        } else {
            var redirectDate = new moment(booking.startDate).format('yyyy-MM-DD');
            history.push(`/admin/calendar/${redirectDate}/${(sessionStorage.getItem('calendarView') || 'week')}`)
        }
    }

    const toggleCalendar = (id) => {
        setBooking((prevState) => {
            let tempState = { ...prevState };
            if (!tempState.calendars)
                tempState.calendars = [];

            var index = tempState.calendars.indexOf(id);

            if (index === -1) {
                tempState.calendars.push(id);
            } else {
                tempState.calendars.splice(index, 1);
            }

            return tempState;
        });
    }

    return (
        <Layout title="Create Event">
            {errors && errors.length > 0 && <>
                <Row className="mb-3">
                    <Col className="d-flex align-items-center">
                        <div className="alert alert-danger p-3 w-100 pb-3">

                            {errors.map(error => <p className="pb-0 mb-0" key={error}>{error}</p>)}
                            </div>
                    </Col>
                </Row>
            </>}

            {booking &&
                <><Row className="mb-3">
                    <Col className="d-flex align-items-center">
                        <label className="form-control-label mr-4" htmlFor="input-username">
                            All Day
                        </label>
                        <label
                            className="custom-toggle custom-toggle-primary"
                            htmlFor="allDay"
                        >
                            <Input
                                type="checkbox"
                                id="allDay"
                                checked={booking.allDay == true}
                                onChange={(e) => updateBooking({ allDay: e.target.checked })}
                            />
                            <span className="custom-toggle-slider rounded-circle" />
                        </label>
                    </Col>
                </Row>

                    <Row className="mb-3">
                    <Col md="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-username">
                                    Start Time
                                </label>
                                <Input
                                    value={new moment(booking.start).format(booking.allDay ? "yyyy-MM-DD" : "yyyy-MM-DDTHH:mm")}
                                    placeholder="Start Date/Time"
                                    type={booking.allDay ? 'date' : 'datetime-local'}
                                    onChange={(e) => updateBooking({ start: e.target.value })}
                                />
                            </FormGroup>
                        </Col>

                        <Col md="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-username">
                                    End Time
                                </label>
                                <Input
                                    value={new moment(booking.end).format(booking.allDay ? "yyyy-MM-DD" : "yyyy-MM-DDTHH:mm")}
                                    placeholder="End Date/Time"
                                    type={booking.allDay ? 'date' : 'datetime-local'}
                                    onChange={(e) => updateBooking({ end: e.target.value })}
                                />
                            </FormGroup>
                        </Col>
                    </Row>


                    <Row className="mb-3">
                        <Col className="d-flex align-items-center">
                            <label className="form-control-label mr-4" htmlFor="input-username">
                                Event Repeats
                            </label>
                            <label
                                className="custom-toggle custom-toggle-primary"
                                htmlFor="repeats"
                            >
                                <Input
                                    type="checkbox"
                                    id="repeats"
                                    checked={booking.repeats == true}
                                    onChange={(e) => updateBooking({ repeats: e.target.checked })}
                                />
                                <span className="custom-toggle-slider rounded-circle" />
                            </label>
                        </Col>
                    </Row>

                    {booking.repeats && (
                        <>
                            <Row className="mb-3">
                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="input-username">
                                            Interval
                                        </label>
                                        <Input
                                            type="select"
                                            value={booking.repeatInterval}
                                            onChange={(e) =>
                                                updateBooking({ repeatInterval: e.target.value })
                                            }
                                        >
                                            <option>Daily</option>
                                            <option>Weekly</option>
                                            <option>Fortnightly</option>
                                            <option>Monthly</option>
                                            <option>Yearly</option>
                                        </Input>
                                    </FormGroup>
                                </Col>

                                <Col md="6">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="input-username">
                                            Until
                                        </label>
                                        <Input
                                            value={booking.repeatEnd || ''}
                                            placeholder="End Date/Time"
                                            type="date"
                                            onChange={(e) => updateBooking({ repeatEnd: e.target.value })}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </>
                )}

                <Row className="mb-3">
                    <Col className="d-flex align-items-center">
                        <label className="form-control-label mr-4" htmlFor="input-username">
                            Event Requires Load In/Out
                        </label>
                        <label
                            className="custom-toggle custom-toggle-primary"
                            htmlFor="requiresLoadInOut"
                        >
                            <Input
                                type="checkbox"
                                id="requiresLoadInOut"
                                checked={booking.requiresLoadInOut == true}
                                onChange={(e) => updateBooking({ requiresLoadInOut: e.target.checked })}
                            />
                            <span className="custom-toggle-slider rounded-circle" />
                        </label>
                    </Col>
                </Row>

                {booking.requiresLoadInOut && (
                    <>
                        <Row className="mb-3">
                            <Col md="6">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-username">
                                        Load In Start
                                    </label>
                                    <Input
                                        value={booking.loadInStart || ''}
                                        placeholder="Load In Start Date/Time"
                                        type="datetime-local"
                                        onChange={(e) => updateBooking({ loadInStart: e.target.value })}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md="6">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-username">
                                        Load Out End
                                    </label>
                                    <Input
                                        value={booking.loadOutEnd || ''}
                                        placeholder="Load Out End Date/Time"
                                        type="datetime-local"
                                        onChange={(e) => updateBooking({ loadOutEnd: e.target.value })}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </>
                )}

                    <Row className="mb-3">
                        <Col>
                            <FormGroup>
                                <label
                                    className="form-control-label w-100"
                                    htmlFor="input-description"
                                >
                                    Location                                
                                </label>

                            <Input
                                type="select"
                                value={booking.location}
                                onChange={(e) =>
                                    updateBooking({ locationId: e.target.value })
                                }
                            >
                                {locations && locations.map(l => <option value={l.id} key={`location_${l.id}`}>{l.name}</option>)}
                            </Input>

                               
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-title">
                                    Title
                                </label>
                                <Input
                                    className="w-100"
                                    placeholder="Event Title"
                                    type="text"
                                    id="input-title"
                                    value={booking.title || ''}
                                    onChange={(e) => updateBooking({ title: e.target.value })}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col>
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-description">
                                    Description
                                </label>
                                <Input
                                    className="w-100"
                                    placeholder="Event Description"
                                    type="textarea"
                                    id="input-description"
                                    rows="5"
                                    value={booking.description || ''}
                                    onChange={(e) => updateBooking({ description: e.target.value })}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                <Row className="mb-3">
                    <Col className="d-flex align-items-center">
                        <label className="form-control-label mr-4" htmlFor="input-username">
                           Event Is Public
                        </label>
                        <label
                            className="custom-toggle custom-toggle-primary"
                            htmlFor="eventIsPublic"
                        >
                            <Input
                                type="checkbox"
                                id="eventIsPublic"
                                checked={booking.public == true}
                                onChange={(e) => updateBooking({ public: e.target.checked })}
                            />
                            <span className="custom-toggle-slider rounded-circle" />
                        </label>
                    </Col>
                </Row>
                    <Row className="mb-3">
                        <Col>
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-description">
                                    Calendars
                                </label>
                                <Row>
                                    {calendars.map((calendar) => (
                                        <Col md="6" className="mb-1" key={`cal_${calendar.id}`}>
                                            <div className="custom-control custom-control-alternative custom-checkbox">
                                                <Input
                                                    type="checkbox"
                                                    id={`cbx_calendar_${calendar.id}`}
                                                    value={calendar.id}
                                                    className="custom-control-input"
                                                    onChange={((e) => toggleCalendar(e.target.value))}
                                                ></Input>
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor={`cbx_calendar_${calendar.id}`}
                                                >
                                                    <span>{calendar.name}</span>
                                                </label>
                                            </div></Col>
                                    ))}
                                </Row>
                            </FormGroup>
                        </Col>
                    </Row>
                <Row className="mb-3">
                    <Col>
                        <FormGroup>
                            <label className="form-control-label" htmlFor="input-description">
                                Notes
                            </label>
                            <Input
                                className="w-100"
                                placeholder="Notes"
                                type="textarea"
                                id="input-description"
                                rows="5"
                                value={booking.notes || ''}
                                onChange={(e) => updateBooking({ notes: e.target.value })}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                    <Row className="mb-3">
                    <Col>
                        <CustomButton
                            color="primary"
                            className="float-right"
                            onClick={submit}
                            loading={loading}
                            >
                                Save
                        </CustomButton>
                        </Col>
                    </Row>



                </>}
        </Layout>
    );
};
