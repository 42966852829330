// import React from 'react';

// const UserContext = React.createContext(null);

// export default UserContext;



import React, { useState } from 'react';
import StorageService from '../services/StorageService';
import ApiService from '../services/ApiService';

const UserContext = React.createContext({
    givenName: '',
    fullName: '',
    token: '',
    sasToken: '',
    isAuthenticated: false,
    canApprove: false,
    pendingApprovals: 0,
    updatePendingApprovals: () => { },
    changeDetails: () => { },
    login: () => { },
    logout: () => { }
});

export default UserContext;

export const UserContextProvider = ({ children }) => {
    const updateValues = values => {
        setValue(prevState => {
            const newState = {
                ...prevState,
                ...values
            };
            if (values) {
                StorageService.set(StorageService.keys.user, newState);
            } else {
                StorageService.remove(StorageService.keys.user);
            }
            return (newState)
        });
    };

 

    const changeDetails = async values => {
    };

    const updatePendingApprovals = (count) => {
        if (value.pendingApprovals !== count)
            updateValues({ pendingApprovals: count });
    }

    const login = async values => {
        const newValues = { ...values };
        newValues.isAuthenticated = values.token != null && values.token.length > 0
        updateValues(newValues);
    };

    const logout = async () => {
        updateValues(null);
        StorageService.clear();
    };

    const [value, setValue] = useState({
        givenName: StorageService.get(StorageService.keys.user)?.givenName || '',
        fullName: StorageService.get(StorageService.keys.user)?.fullName || '',
        token: StorageService.get(StorageService.keys.user)?.token || null,
        isAuthenticated: StorageService.get(StorageService.keys.user)?.isAuthenticated || false,
        canApprove: StorageService.get(StorageService.keys.user)?.canApprove || false,
        pendingApprovals: StorageService.get(StorageService.keys.user)?.pendingApprovals || 0,
        changeDetails: values => changeDetails(values),
        updatePendingApprovals: count => updatePendingApprovals(count),
        login: values => login(values),
        logout
    });



    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
